class APIError {

	constructor(httpStatus, errorCode, message) {
		this._httpStatus = httpStatus;
		this._errorCode = errorCode;
		this._message = message;
	}

	get httpStatus() {
		return this._httpStatus;
	}

	get errorCode() {
		return this._errorCode;
	}

	get message() {
		return this._message;
	}
}

export default APIError;
