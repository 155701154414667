import {Card, Typography} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import {Datagrid, DateField, ListContextProvider, TextField, useList, useTranslate} from 'react-admin';


const SimilarCandidatesList = ({data}) => {
	const translate = useTranslate();

	const formattedData = data.map(data => {
		return {
			id: data.id,
			similarity: data.similarity.toFixed(2),
			lastJobTitle: data.title,
			appliedJobTitle: data.jobTitle,
			jobLocation: data.jobLocation,
			applDate: new Date(data.applDate),
			skills: data.skills ? data.skills.join(', ') : '',
		};
	});

	const sort = {field: 'similarity', order: 'desc'};
	const listContext = useList({data: formattedData, sort: sort});

	return (
		<Card>
			<ListContextProvider value={listContext}>
				<Datagrid size="medium" bulkActionButtons={false}>
					<TextField label={<Typography variant="h6">{translate('resources.salesreport.data.applied_job_title')}</Typography>} source="appliedJobTitle" />
					<TextField label={<Typography variant="h6">{translate('resources.salesreport.data.job_location')}</Typography>} source="jobLocation" />
					<DateField label={<Typography variant="h6">{translate('resources.salesreport.data.last_applied_date')}</Typography>} source="applDate" locales="de-CH" options={{dateStyle: 'medium'}} />
					<TextField label={<Typography variant="h6">{translate('resources.salesreport.data.similarity')}</Typography>} source="similarity" />
					<TextField label={<Typography variant="h6">{translate('resources.salesreport.data.last_job_title')}</Typography>} source="lastJobTitle" />
					<TextField label={<Typography variant="h6">{translate('resources.salesreport.data.skills')}</Typography>} source="skills" />
				</Datagrid>
			</ListContextProvider>
		</Card>
	);
};


SimilarCandidatesList.propTypes = {
	data: PropTypes.array.isRequired
};

export default SimilarCandidatesList;