import {Link, Typography} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import {useRecordContext} from 'react-admin';
import {RESOURCE_DECRYPTION} from '../providers/DataProvider';


const isValidURL = (url) => {
	return url.startsWith('http');
};

const EncryptionField = ({source}) => {
	const record = useRecordContext();
	const token = record[source];
	const url = `${window.location.protocol}//${window.location.host}/#/${RESOURCE_DECRYPTION}/${token}/show`;

	if (isValidURL(url)) {
		return <Link href={url} target="_blank" rel="noopener noreferrer" variant="body2" sx={{'overflowWrap': 'anywhere'}}>{url}</Link>;
	} else {
		return <Typography variant="body2">Invalid URL: {url}</Typography>;
	}
};

EncryptionField.defaultProps = {
	addLabel: true,
	label: 'resources.encrypt.fields.link'
};

EncryptionField.propTypes = {
	record: PropTypes.object,
	source: PropTypes.string.isRequired
};

export default EncryptionField;
