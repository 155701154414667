import {Slider, Typography} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslate} from 'react-admin';
import {useController} from 'react-hook-form';


const SliderInput = (props) => {
	const input = useController({name: props.source, defaultValue: props.defaultValue});
	const translate = useTranslate();

	return (
		<div style={{width: props.fullWidth ? '100%' : 'auto'}}>
			<div style={{width: '100%'}}>
				<Typography variant="caption">{translate(props.label)}</Typography>
			</div>
			<div style={{padding: '0.5em 1em'}}>
				<Slider {...input.field} min={props.min} max={props.max} step={props.step} marks={props.marks} valueLabelDisplay="auto" color="secondary" />
			</div>
		</div>
	);
};

SliderInput.propTypes = {
	source: PropTypes.string.isRequired,
	label: PropTypes.string,
	defaultValue: PropTypes.number,
	min: PropTypes.number.isRequired,
	max: PropTypes.number.isRequired,
	step: PropTypes.number,
	marks: PropTypes.array,
	fullWidth: PropTypes.bool
};

export default SliderInput;