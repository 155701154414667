import React from 'react';
import {Show, SimpleShowLayout} from 'react-admin';
import IframeField from '../IframeField';


const MediaFileShow = (props) => (
	<Show title="Media File" {...props}>
		<SimpleShowLayout>
			<IframeField source="plaintext" />
		</SimpleShowLayout>
	</Show>
);
export default MediaFileShow;
