import {ToggleButton, ToggleButtonGroup, Typography} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import {useInput, useTranslate} from 'react-admin';


const ToggleButtonGroupNumberInput = (props) => {
	const {field} = useInput({
		source: props.source,
		defaultValue: props.defaultValue,
		parse: v => parseInt(v)
	});
	const translate = useTranslate();

	const children = props.choices.map(c => {
		return (
			<ToggleButton value={c.value} key={c.value} style={{textTransform: 'none'}}>
				{translate(c.label)}
			</ToggleButton>
		);
	});

	return (
		<div style={{width: props.fullWidth ? '100%' : 'auto'}}>
			<div style={{width: '100%'}}>
				<Typography variant="caption">{translate(props.label)}</Typography>
			</div>
			<div style={{padding: '0.5em 0'}}>
				<ToggleButtonGroup exclusive {...field} fullWidth={props.fullWidth} size="small">
					{children}
				</ToggleButtonGroup>
			</div>
		</div>
	);
};

ToggleButtonGroupNumberInput.propTypes = {
	source: PropTypes.string.isRequired,
	label: PropTypes.string,
	defaultValue: PropTypes.number,
	choices: PropTypes.array.isRequired,
	fullWidth: PropTypes.bool
};

export default ToggleButtonGroupNumberInput;