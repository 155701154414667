import {PendingActions} from '@mui/icons-material';
import React, {useState} from 'react';
import {Edit, SaveButton, ShowButton, Toolbar} from 'react-admin';
import SalesReportForm from './SalesReportForm';


const SalesReportEdit = (props) => {

	const EditToolbar = () => {
		const [cancelButtonDisabled, setCancelButtonDisabled] = useState(false);

		const handleSaveClick = () => {
			setCancelButtonDisabled(true);
		};

		return (
			<Toolbar>
				<SaveButton label="resources.salesreport.action.update" icon={<PendingActions />} onClick={handleSaveClick} />
				<ShowButton size="small" label="resources.salesreport.action.cancel" icon={null} sx={{marginLeft: '1em'}} disabled={cancelButtonDisabled} />
			</Toolbar>
		);
	};

	return (
		<Edit title="resources.salesreport.name" redirect="show" actions={null} mutationMode="pessimistic" sx={{marginBottom: '2em'}} {...props}>
			<SalesReportForm toolbar={<EditToolbar />} />
		</Edit>
	);
};
export default SalesReportEdit;
