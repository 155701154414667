import {Button, CardContent, CircularProgress, Typography, useTheme} from '@mui/material';
import {styled} from '@mui/material/styles';
import * as React from 'react';
import {email, Form, LoginFormClasses, required, TextInput, useNotify, useSafeSetState, useTranslate} from 'react-admin';
import AuthProvider from '../../providers/AuthProvider';


export const ForgotPasswordForm = (props) => {
	const {className, onCancel} = props;
	const [loading, setLoading] = useSafeSetState(false);
	const translate = useTranslate();
	const notify = useNotify();
	const theme = useTheme();

	const submit = (values) => {
		setLoading(true);
		AuthProvider.forgotPassword(values.email).then(() => {
			setLoading(false);
		}).catch((err) => {
			setLoading(false);
			notify(err, {
				type: 'error'
			});
		});
	};

	const cancel = () => {
		if (typeof onCancel === 'function') {
			onCancel();
		}
	}

	return (
		<StyledForm onSubmit={submit} mode="onChange" noValidate className={className}>
			<CardContent className={LoginFormClasses.content}>

				<Typography variant="h6" color="text.primary" sx={{marginBottom: theme.spacing(2), textAlign: 'center'}}>
					{translate('proactive.login.forgot_password_title')}
				</Typography>

				<Typography variant="body1" color="text.secondary" sx={{marginBottom: theme.spacing(2), textAlign: 'center'}}>
					{translate('proactive.login.forgot_password_description')}
				</Typography>

				<TextInput
					autoFocus
					source="email"
					label={translate('proactive.login.labels.email')}
					validate={[required(), email()]}
					type="text"
					fullWidth
				/>

				<Button
					variant="contained"
					type="submit"
					color="secondary"
					disabled={loading}
					fullWidth
					className={LoginFormClasses.button}
				>
					{loading ? (
						<CircularProgress
							className={LoginFormClasses.icon}
							size={19}
							thickness={3}
						/>
					) : (
						translate('proactive.common.send')
					)}
				</Button>

				{typeof onCancel === 'function' && <Button
					variant="contained"
					type="button"
					color="primary"
					disabled={loading}
					fullWidth
					className={LoginFormClasses.button}
					onClick={cancel}
				>
					{translate('proactive.common.cancel')}
				</Button>}
			</CardContent>
		</StyledForm>
	);
};

const StyledForm = styled(Form, {
	overridesResolver: (props, styles) => styles.root
})(({theme}) => ({
	[`& .${LoginFormClasses.content}`]: {
		width: 300
	},
	[`& .${LoginFormClasses.button}`]: {
		marginTop: theme.spacing(2)
	},
	[`& .${LoginFormClasses.icon}`]: {
		margin: theme.spacing(0.3)
	}
}));