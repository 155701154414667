import {CREATE, DELETE, GET_LIST, GET_MANY, GET_MANY_REFERENCE, GET_ONE, UPDATE} from 'react-admin';
import Logger from '../logger';
import APIError from './APIError';
import {
	BASE_URL,
	getAuthToken,
	getPaginationFromParams,
	getResourcePath,
	RESOURCE_DECRYPTION,
	RESOURCE_ENCRYPTION,
	RESOURCE_MEDIAFILES,
	RESOURCE_SALES_REPORT,
	RESOURCE_SALESFORCE_FILES
} from './DataProvider';

export const emptyResponse = {total: 0, data: []};

export class defaultClient {

	async handle(type, resource, params) {
		Logger.debug(`DataProvider.handle: type=${type} resource=${resource} params=${JSON.stringify(params)}`);

		const path = getResourcePath(resource, params);

		const request = await this._buildRequest(type, resource, path, params);
		const json = await this._sendRequest(request);

		return this._convertResponse(type, json, resource, params);
	}

	async _buildRequest(type, resource, path, params) {

		const token = await getAuthToken();

		let url = '';
		const options = {
			method: 'GET',
			headers: new Headers({
				'Authorization': `Bearer ${token.jwtToken}`
			}),
			mode: 'cors'
		};

		const pagination = getPaginationFromParams(params);
		// const sorting = getSortingFromParams(params);

		switch (type) {

			case GET_LIST:
				url = `${BASE_URL}/${path}?limit=${pagination.limit}&offset=${pagination.offset}`;
				break;

			case GET_ONE:
				url = `${BASE_URL}/${path}/${encodeURIComponent(params.id)}`;
				break;

			case GET_MANY:
				url = `${BASE_URL}/${path}?ids=${params.ids.join(',')}`;
				break;

			case GET_MANY_REFERENCE:
				url = `${BASE_URL}/${path}?${params.target}=${params.id}&limit=${pagination.limit}&offset=${pagination.offset}`;
				break;

			case CREATE:
				url = `${BASE_URL}/${path}`;
				options.method = 'POST';
				options.body = this._composeRequestBody(resource, params.data);
				break;

			case UPDATE:
				switch (resource) {
					case RESOURCE_SALES_REPORT:
						url = `${BASE_URL}/${path}`;
						options.method = 'POST';
						options.body = this._composeRequestBody(resource, params.data);
						break;
					default:
						url = `${BASE_URL}/${path}/${params.id}`;
						options.method = 'PUT';
						options.body = this._composeRequestBody(resource, params.data);
						break;
				}
				break;

			case DELETE:
				url = `${BASE_URL}/${path}/${params.id}`;
				options.method = 'DELETE';
				break;

			default:
				throw new Error(`Unsupported Data Provider request type: ${type}`);

		}

		Logger.debug(`DataProvider.buildRequest: url=${url} options=${JSON.stringify(options)}`);
		const request = new Request(url, options);
		return Promise.resolve(request);
	}

	_composeRequestBody(resource, data) {

		const body = {};
		const fields = [];

		switch (resource) {
			case RESOURCE_ENCRYPTION:
				fields.push('plaintext');
				break;
			case RESOURCE_SALESFORCE_FILES:
				fields.push('base64Data', 'optionalMetadata');
				break;
			case RESOURCE_SALES_REPORT:
				fields.push('jobContent','dateInterval','minSimilarity');
				break;
			default:
				break;
		}

		fields.forEach(field => {
			if (data[field] !== undefined)
				body[field] = data[field];
		});

		return JSON.stringify(body);
	}

	_sendRequest(request) {
		Logger.info(`${request.method} ${request.url}`);
		return fetch(request)
		.then(response => {

			if (response.status >= 500) {
				throw new APIError(response.status, 0, 'proactive.errors.server_error');
			}

			if (response.status >= 400) {
				return response.json().then(json => {
					throw new APIError(response.status, json.errorCode, `proactive.errors.error_${json.errorCode}`);
				});
			}

			return response.json();
		})
		.catch((error) => {
			if (error.httpStatus)
				throw error;
			else
				throw new Error('proactive.errors.offline');
		});
	}

	_convertResponse(type, json, resource, params) {

		const response = {};

		switch (type) {

			case CREATE:
			case UPDATE:
				switch (resource) {
					case RESOURCE_ENCRYPTION:
						response.data = json;
						response.data.id = json.ciphertext;
						break;
					case RESOURCE_SALES_REPORT:
						response.data = json;
						response.data.jobContent = params.data.jobContent;
						break;
					default:
						response.data = json
				}
				break;

			case GET_LIST:
			case GET_MANY_REFERENCE:
			case GET_MANY:
				response.data = (json.count > 0 ? json.results : []);
				response.total = json.total;
				break;

			case GET_ONE:
				response.data = json;
				switch (resource) {
					case RESOURCE_MEDIAFILES:
					case RESOURCE_DECRYPTION:
						response.data.id = params.id;
						break;
					case RESOURCE_ENCRYPTION:
						response.data.id = json.ciphertext;
						break;
					default:
						break;
				}
				break;
			default:
				response.data = json;
		}

		return response;
	}
}

const singleton = new defaultClient();
const handler = (type, resource, params) => {
	return singleton.handle(type, resource, params);
};
export default handler;