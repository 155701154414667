import {Button, CardContent, CircularProgress} from '@mui/material';
import {styled} from '@mui/material/styles';
import * as React from 'react';
import {Form, LoginFormClasses, required, TextInput, useLogin, useNotify, useSafeSetState, useTranslate} from 'react-admin';


export const LoginForm = (props) => {
	const {className, redirectTo} = props;
	const [loading, setLoading] = useSafeSetState(false);
	const translate = useTranslate();
	const notify = useNotify();
	const login = useLogin();

	const submit = (values) => {
		setLoading(true);
		login(values, redirectTo).then(() => {
			setLoading(false);
		}).catch((err) => {
			setLoading(false);
			notify(err, {
				type: 'error'
			});
		});
	};

	return (
		<StyledForm onSubmit={submit} mode="onChange" noValidate className={className}>
			<CardContent className={LoginFormClasses.content}>
				<TextInput
					autoFocus
					source="username"
					label={translate('ra.auth.username')}
					validate={required()}
					fullWidth
				/>
				<TextInput
					source="password"
					label={translate('ra.auth.password')}
					type="password"
					autoComplete="current-password"
					validate={required()}
					fullWidth
				/>

				<Button
					variant="contained"
					type="submit"
					color="secondary"
					disabled={loading}
					fullWidth
					className={LoginFormClasses.button}
				>
					{loading ? (
						<CircularProgress
							className={LoginFormClasses.icon}
							size={19}
							thickness={3}
						/>
					) : (
						translate('ra.auth.sign_in')
					)}
				</Button>
			</CardContent>
		</StyledForm>
	);
};

const StyledForm = styled(Form, {
	overridesResolver: (props, styles) => styles.root
})(({theme}) => ({
	[`& .${LoginFormClasses.content}`]: {
		width: 300
	},
	[`& .${LoginFormClasses.button}`]: {
		marginTop: theme.spacing(2)
	},
	[`& .${LoginFormClasses.icon}`]: {
		margin: theme.spacing(0.3)
	}
}));