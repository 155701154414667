import globals from '../globals';
import Logger from '../logger';
import AuthProvider from './AuthProvider';
import DefaultClient from './DefaultClient';
import DefaultClientWithCaching from './DefaultClientWithCaching';
import SalesforceClient from './SalesforceClient';


const dataProvider = (type, resource, params) => {

	switch (resource) {
		case RESOURCE_ENCRYPTION:
		case RESOURCE_SALES_REPORT:
			return DefaultClientWithCaching(type, resource, params);
		case RESOURCE_SALESFORCE_FILES:
			return SalesforceClient(type, resource, params);
		default:
			return DefaultClient(type, resource, params);
	}

};
export default dataProvider;

export const BASE_URL = `${globals.API_URL}/private`;

export const RESOURCE_MEDIAFILES = 'mediafiles';
export const RESOURCE_ENCRYPTION = 'encrypt';
export const RESOURCE_DECRYPTION = 'decrypt';
export const RESOURCE_SALESFORCE_FILES = 'salesforce/files';
export const RESOURCE_STORAGE = 'storage';
export const RESOURCE_SALES_REPORT = 'salesreport';

const RESOURCE_PATH_MAPPING = {};
RESOURCE_PATH_MAPPING[RESOURCE_MEDIAFILES] = 'decrypt';
RESOURCE_PATH_MAPPING[RESOURCE_ENCRYPTION] = 'encrypt';
RESOURCE_PATH_MAPPING[RESOURCE_DECRYPTION] = 'decrypt';
RESOURCE_PATH_MAPPING[RESOURCE_SALESFORCE_FILES] = 'salesforce/files';
RESOURCE_PATH_MAPPING[RESOURCE_STORAGE] = 'binarystorage';
RESOURCE_PATH_MAPPING[RESOURCE_SALES_REPORT] = 'salesreport';

const isSupportedResource = (resource) => {
	return RESOURCE_PATH_MAPPING[resource];
};

export const getResourcePath = (resource, params) => {

	if (!isSupportedResource(resource)) {
		throw new Error(`Unsupported Data Provider resource: ${resource}`);
	}

	const path = RESOURCE_PATH_MAPPING[resource];

	switch (resource) {
		case RESOURCE_SALESFORCE_FILES:
			return path + '/' + params.data.salesforceRecordId;
		default:
			return path;
	}
};

export const getAuthToken = async () => {
	return await AuthProvider.getAccessToken();
};

export const getPaginationFromParams = (params) => {

	let pagination = {
		limit: 10,
		offset: 0
	};

	if (params.pagination) {
		pagination.limit = params.pagination.perPage;
		pagination.offset = (params.pagination.page - 1) * pagination.limit;
	}

	return pagination;
};

// const getSortingFromParams = (params) => {
//
// 	let sorting = {
// 		field: "id",
// 		order: "ASC"
// 	};
//
// 	if (params.sort) {
// 		sorting.field = params.sort.field;
// 		sorting.order = params.sort.order;
// 	}
//
// 	return sorting;
// };

export const decodeJSONBytes = (bytes) => {
	try {
		const json = new TextDecoder().decode(bytes);
		return JSON.parse(json);
	} catch (error) {
		Logger.error(error);
		return null;
	}
};
