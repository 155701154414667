import {Button, CardContent, CircularProgress, Typography, useTheme} from '@mui/material';
import {styled} from '@mui/material/styles';
import qrcode from 'qrcode';
import * as React from 'react';
import {Form, LoginFormClasses, maxLength, minLength, required, TextInput, useNotify, useSafeSetState, useTranslate} from 'react-admin';
import Globals from '../../globals';
import Logger from '../../logger';
import AuthProvider from '../../providers/AuthProvider';


export const MFASetupForm = (props) => {
	const {className} = props;
	const [loading, setLoading] = useSafeSetState(false);
	const translate = useTranslate();
	const notify = useNotify();
	const theme = useTheme();

	const generateQRCode = () => {
		let service = translate('proactive.login.mfa_service_name');
		if (Globals.ENV !== 'prod') {
			service = `${service} (${Globals.ENV})`;
		}

		const otpAuth = `otpauth://totp/${encodeURIComponent(service)}:${AuthProvider.getUsername()}?secret=${AuthProvider.getMFASecretCode()}&issuer=${encodeURIComponent(service)}`;

		let result = '';
		qrcode.toDataURL(otpAuth, function(err, data_url) {
			if (err) {
				Logger.error('QR error ' + err);
			} else {
				result = data_url;
			}
		});

		return result;
	};

	const submit = (values) => {
		setLoading(true);
		AuthProvider.setupMFAVerifyCode(values.totpCode).then(() => {
			setLoading(false);
		}).catch((err) => {
			setLoading(false);
			notify(err, {
				type: 'error'
			});
		});
	};

	return (
		<StyledForm onSubmit={submit} mode="onChange" noValidate className={className}>
			<CardContent className={LoginFormClasses.content}>

				<div style={{width: '100%', textAlign: 'center'}}>
					<img src={generateQRCode()} alt="QR Code" />
				</div>

				<Typography variant="body1" color="text.secondary" sx={{marginBottom: theme.spacing(2), textAlign: 'center'}}>
					{translate('proactive.login.mfa_setup_description')}
				</Typography>

				<TextInput
					autoFocus
					source="totpCode"
					label={translate('proactive.login.labels.mfa_code')}
					type="text"
					autoComplete="off"
					validate={[required(), minLength(6), maxLength(6)]}
					fullWidth
				/>

				<Button
					variant="contained"
					type="submit"
					color="secondary"
					disabled={loading}
					fullWidth
					className={LoginFormClasses.button}
				>
					{loading ? (
						<CircularProgress
							className={LoginFormClasses.icon}
							size={19}
							thickness={3}
						/>
					) : (
						translate('ra.action.confirm')
					)}
				</Button>
			</CardContent>
		</StyledForm>
	);
};

const StyledForm = styled(Form, {
	overridesResolver: (props, styles) => styles.root
})(({theme}) => ({
	[`& .${LoginFormClasses.content}`]: {
		width: 300
	},
	[`& .${LoginFormClasses.button}`]: {
		marginTop: theme.spacing(2)
	},
	[`& .${LoginFormClasses.icon}`]: {
		margin: theme.spacing(0.3)
	}
}));