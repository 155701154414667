import {ContentCopy} from '@mui/icons-material';
import {Button} from '@mui/material';
import React from 'react';
import {CreateButton, Show, SimpleShowLayout, TopToolbar, useRecordContext, useTranslate} from 'react-admin';
import {RESOURCE_DECRYPTION} from '../../providers/DataProvider';
import EncryptionField from '../EncryptionField';


const copyToClipboard = (record) => {
	const url = `${window.location.protocol}//${window.location.host}/#/${RESOURCE_DECRYPTION}/${record.ciphertext}/show`;
	navigator.clipboard.writeText(url);
};

const EncryptShow = (props) => {
	const translate = useTranslate();

	const ShowActions = () => {
		const record = useRecordContext();
		return (
			<TopToolbar>
				<CreateButton size="medium" label="resources.encrypt.action.create" />
				<Button color="primary" startIcon={<ContentCopy fontSize="small" />} onClick={() => {
					copyToClipboard(record);
				}}>
					{translate('resources.encrypt.action.copy_to_clipboard')}
				</Button>
			</TopToolbar>
		);
	};

	return (
		<Show title="resources.encrypt.name" actions={<ShowActions props={props} />} {...props}>
			<SimpleShowLayout>
				<EncryptionField source="ciphertext" />
			</SimpleShowLayout>
		</Show>
	);
};
export default EncryptShow;
