import React from 'react';
import {RecordContextProvider, ShowView, SimpleShowLayout, useShowController} from 'react-admin';
import IframeField from '../IframeField';
import PlaintextField from '../PlaintextField';
import RedirectField from '../RedirectField';


const mediaFileURLRegex = /^https:\/\/(stage.)?media.jobs.ch\S*$/;
const nonMediaFileURLRegex = /^https?:\/\/(?!(stage.)?media.jobs.ch)\S*$/;
const genericURLRegex = /^https?:\/\/\S*$/;

const DecryptShow = (props) => {
	const {record} = useShowController();

	return (
		<RecordContextProvider value={record}>
			<ShowView title="resources.encrypt.name" {...props}>
				<SimpleShowLayout>
					{record && record.plaintext.match(mediaFileURLRegex) && <IframeField source="plaintext" />}
					{record && record.plaintext.match(nonMediaFileURLRegex) && <RedirectField source="plaintext" />}
					{record && !record.plaintext.match(genericURLRegex) && <PlaintextField source="plaintext" />}
				</SimpleShowLayout>
			</ShowView>
		</RecordContextProvider>
	);
};
export default DecryptShow;
