import {Typography} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import {useRecordContext} from 'react-admin';


const isValidURL = (url) => {
	return url.startsWith('http');
};

const IframeField = ({source}) => {
	const record = useRecordContext();
	const url = record[source];

	if (isValidURL(url)) {
		return <iframe title="mediafile" src={url} width="100%" height="800" />;
	} else {
		return <Typography variant="body2">Invalid URL: {url}</Typography>;
	}
};

IframeField.propTypes = {
	record: PropTypes.object,
	source: PropTypes.string.isRequired
};

export default IframeField;
