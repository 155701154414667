import {
	CategoryScale,
	Chart as ChartJS,
	Colors,
	Filler,
	Legend,
	LinearScale,
	LineElement,
	PointElement,
	Title,
	Tooltip
} from 'chart.js';
import PropTypes from 'prop-types';
import React, {useEffect, useRef, useState} from 'react';
import {Line} from 'react-chartjs-2';

ChartJS.register(
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend,
	Filler,
	Colors
);

const ApplicantHistogramChart = ({data}) => {
	const chartRef = useRef(null);
	const [labels, setLabels] = useState([]);
	const [values, setValues] = useState([]);
	const [options] = useState({
		responsive: true,
		aspectRatio: 6,
		options: {
			width: '100%'
		},
		datasets: {
			line: {
				borderColor: 'rgba(102,255,163,1.0)',
				backgroundColor: 'rgba(102,255,163,0.8)',
				fill: true,
				pointStyle: false,
				borderWidth: 1
			}
		},
		plugins: {
			legend: {
				display: false
			}
		}
	});

	useEffect(() => {

		if (!data) {
			return;
		}

		sortDataByDateAsc(data);
		setLabels(data.map((l) => {
			return new Date(l.date).toISOString().slice(0, 10);
		}));

		setValues(data.map((d) => {
			return d.count;
		}));

	}, [data]);

	useEffect(() => {
		window.onresize = () => {
			const chart = chartRef.current;
			if (chart) {
				chart.resize();
			}
		};
	}, []);

	return (
		<div>
			<Line ref={chartRef} options={options} data={{labels: labels, datasets: [{data: values}]}} />
		</div>
	);
};

function sortDataByDateAsc(data) {
	data.sort(function compare(a, b) {
		let dateA = new Date(a.date);
		let dateB = new Date(b.date);
		return dateA - dateB;
	});
}

ApplicantHistogramChart.propTypes = {
	data: PropTypes.array.isRequired
};

export default ApplicantHistogramChart;
