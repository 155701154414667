import {EnhancedEncryption} from '@mui/icons-material';
import React from 'react';
import {Create, maxLength, required, SaveButton, SimpleForm, TextInput, Toolbar, useTranslate} from 'react-admin';


const plaintextMaxLength = 2 * 1024;
const trimWhitespaces = (data) => {
	data.plaintext = data.plaintext.trim();
	return data;
};

const EncryptCreate = (props) => {

	const translate = useTranslate();
	const EncryptToolbar = props => (
		<Toolbar {...props}>
			<SaveButton label="resources.encrypt.action.save" icon={<EnhancedEncryption />} />
		</Toolbar>
	);

	return (
		<Create title="resources.encrypt.name" transform={trimWhitespaces} {...props}>
			<SimpleForm toolbar={<EncryptToolbar />} redirect="show">
				<TextInput autoFocus multiline fullWidth source="plaintext" label="resources.encrypt.fields.plaintext.label" validate={[required(), maxLength(plaintextMaxLength)]} />
				<small className="input-help">{translate('resources.encrypt.fields.plaintext.help', {max_length: plaintextMaxLength})}</small>
			</SimpleForm>
		</Create>
	);
};
export default EncryptCreate;
