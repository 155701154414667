import {Card} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';


const ReportCard = ({children}) => {

	return (
		<Card sx={{color: '#4b4b4b'}}>
			{children}
		</Card>
	);
};

ReportCard.propTypes = {
	children: PropTypes.element.isRequired
};

export default ReportCard;