const translations = {
	de: {
		resources: {
			encrypt: {
				name: 'Encryption Helper',
				fields: {
					plaintext: {
						label: 'URL oder Text',
						help: 'Max. %{max_length} Zeichen'
					},
					link: 'Verschlüsselter Link'
				},
				action: {
					save: 'Encrypt',
					create: 'Neu',
					copy_to_clipboard: 'In Zwischenablage kopieren'
				}
			},
			decrypt: {
				fields: {
					plaintext: 'Text'
				}
			},
			'salesforce/files': {
				name: 'Salesforce Documents'
			},
			salesforce: {
				files: {
					name: 'Salesforce Documents',
					action: {
						save: 'Hochladen'
					},
					fields: {
						base64Data: {
							label: 'Datei',
							help: 'Max. %{mb} MB'
						}
					}
				}
			},
			salesreport: {
				name: 'Candidate Explorer',
				fields: {
					job_content: {
						label: 'Job Beschreibung'
					},
					date_interval: {
						label: 'Datumsbereich',
						one_month: '1 Monat',
						two_months: '2 Monate',
						three_months: '3 Monate',
						six_months: '6 Monate'
					},
					min_similarity: {
						label: 'Min. Similarity'
					}
				},
				action: {
					create: 'Generieren',
					update: 'Aktualisieren',
					cancel: 'Abbrechen'
				},
				data: {
					similar_candidates: 'Passende Kandidaten',
					similar_jobs: 'Ähnliche Jobs',
					similar_jobs_note: 'ausschliesslich mit Bewerber',
					avg_applicant_count: 'Durchschnittliche Anzahl Bewerber',
					avg_applicant_count_note: 'ausschliesslich messbare Bewerbungen',
					applicant_behaviour: 'Bewerberverhalten',
					locations: 'Top Arbeitsorte',
					segments: 'Segmente',
					categories: 'Top Berufsfelder',
					applicant_histogram: 'Letzte Bewerbungen',
					candidate_count: 'Anzahl Talente',
					other: 'Andere',
					unknown: 'Unbekannt',
					applied_job_title: 'Bewerbung als',
					job_location: 'Arbeitsort',
					similarity: 'Score',
					last_applied_date: 'Datum',
					last_job_title: 'Letzter Job',
					skills: 'Skills'
				}
			}
		},
		proactive: {
			login: {
				forgot_password: 'Passwort vergessen?',
				forgot_password_title: 'Passwort wiederherstellen',
				forgot_password_description: 'Geben Sie hier Ihre Email-Adresse ein. Ihnen wird dann ein Code zugesendet, den Sie zusammen mit einem neuen Passwort eingeben können.',
				forgot_password_verification_title: 'Verifizieren Sie Ihre Email-Adresse',
				forgot_password_verification_description: 'Geben Sie hier den zugesendeten Code und Ihr neues Passwort ein.',
				user_registration_needed: 'Bitte schliessen Sie die Registrierung ab',
				user_registration_success: 'Das Registrierung wurde erfolgreich abgeschlossen',
				reset_password_success: 'Das neue Passwort wurde erfolgreich gespeichert',
				verification_code_sent: 'Eine Email mit dem Verifizierungscode wurde versendet',
				set_pw_title: 'Konto erstellen',
				set_pw_description: 'Bevor Sie Ihren Account benutzen können, benötigen wir von Ihnen folgende Angaben:',
				setup_mfa_needed: 'Bitte richten Sie Multi-Faktor-Authentisierung (MFA) ein',
				mfa_service_name: 'Spotted Console', // displayed in authenticator app
				mfa_setup_title: 'Multi-Faktor-Authentisierung (MFA)',
				mfa_setup_description: 'Bitte QR Code mit einer MFA/2FA App scannen (z.B. Authy oder Google Authenticator).',
				mfa_setup_success: 'Multi-Faktor-Authentisierung (MFA) erfolgreich eingerichtet',
				mfa_setup_failed: 'Fehler bei der Einrichtung von Multi-Faktor-Authentisierung (MFA)',
				totp_challenge_needed: 'Multi-Faktor-Authentisierung (MFA)',
				totp_challenge_success: 'Multi-Faktor-Authentisierung (MFA) erfolgreich',
				totp_challenge_failed: 'Fehler bei der Eingabe des Multi-Faktor-Authentisierung (MFA) Code',
				totp_challenge_title: 'Multi-Faktor-Authentisierung (MFA) Code',
				totp_challenge_description: 'Bitte benutzen Sie den Code aus Ihre Authentifizeriungs-App.',
				unknown_error: 'Unbekannter Fehler. Bitte kontaktieren Sie den Administrator.',
				labels: {
					password: 'Passwort',
					password_repeated: 'Passwort wiederholen',
					firstname: 'Vorname',
					lastname: 'Nachname',
					email: 'Email',
					verification_code: 'Verifizierungscode',
					totp_code: 'Generierter Code eingeben',
					mfa_code: 'Generierter Code eingeben'
				}
			},
			errors: {
				offline: 'Server nicht erreichbar, sind Sie offline?',
				server_error: 'Unbekannter Fehler, bitte versuchen Sie es später nochmals.',
				error_1000: 'Unbekannter Fehler, bitte versuchen Sie es später nochmals.',
				error_1001: 'Zuviele Anfragen, bitte warten Sie einen Moment.',
				error_1100: 'Unbekannter Fehler, bitte versuchen Sie es später nochmals.',
				error_1101: 'Bitte füllen Sie alle Felder aus und versuchen Sie es nochmals.',
				error_1102: 'Unbekannter Fehler, bitte versuchen Sie es später nochmals.',
				error_1103: 'Unbekannter Fehler, bitte versuchen Sie es später nochmals.',
				error_1200: 'Kein Zugriff, bitte melden Sie sich zuerst an.',
				error_1201: 'Ihre Sitzung ist abgelaufen, bitte melden Sie sich neu an.',
				error_1202: 'Sie haben keine Berechtigung diese Aktion auszuführen.',
				error_2000: 'Eine Datei existiert bereits für dieses Salesforce Dokument.',
				error_3000: 'Es konnten keine Informationen extrahiert werden. Bitte ändern Sie die Job Beschreibung und versuchen Sie es erneut.',
				error_5000: 'Die Datei ist zu gross um hochzuladen. Es sind max. 30 MB pro Datei erlaubt.',
				UserNotFoundException: 'Nutzername oder Passwort ist nicht korrekt',
				NotAuthorizedException: 'Nutzername oder Passwort ist nicht korrekt',
				PasswordResetRequiredException: 'Sie müssen Ihr Passwort ändern, bevor Sie sich wieder einloggen können',
				InvalidPasswordException: 'Sie haben ein ungültiges Passwort gewählt',
				LimitExceededException: 'Zu viele fehlerhafte Versuche, probieren Sie es später nochmal',
				TooManyRequestsException: 'Zu viele Versuche, probieren Sie es später nochmal',
				InvalidParameterException: 'Ihre Anfrage konnte nicht verarbeitet werden, bitte überprüfen Sie Ihre Eingaben',
				InternalErrorException: 'Ein interner Fehler ist aufgetreten, bitte versuchen Sie es erneut',
				ExpiredCodeException: 'Der eingegebene Code ist abgelaufen, bitte versuchen Sie es erneut',
				CodeMismatchException: 'Ungültiger Code eingegeben, bitte versuchen Sie es erneut',
				UserNotConfirmedException: 'Ihr Nutzer wurde noch nicht bestätigt, setzen Sie sich mit uns in Verbindung',
				CodeDeliveryFailureException: 'Der Verifizierungscode konnte nicht versendet werden',
				ForbiddenException: 'Anfrage von unbekannter IP-Adresse. Bitte verwenden Sie eine vertrauenswürdige Verbindung (VPN).',
				no_user_stored: 'Bitte loggen Sie sich erneut ein',
				get_session_failed: 'Bitte loggen Sie sich erneut ein',
				request_failed_with_auth_error: 'Bitte loggen Sie sich erneut ein',
				wrong_user_configuration: 'Benutzer ist falsch konfiguriert. Bitte kontaktieren Sie den Administrator.',
				file_rejection_unknown: 'Die Datei kann nicht hochgeladen werden, bitte versuche eine andere Datei.'
			},
			validation: {
				pw_not_equal: 'Die Passwörter stimmen nicht überein',
				no_lower_case: 'mind. 1 Kleinbuchstaben',
				no_upper_case: 'mind. 1 Grossbuchstaben',
				no_special_char: 'mind. 1 Sonderzeichen',
				no_number: 'mind. 1 Zahl'
			},
			common: {
				cancel: 'Abbrechen',
				update: 'Aktualisieren',
				verify: 'Verifizieren',
				send: 'Senden'
			},
			months: 'Monate',
			years: 'Jahre'
		}
	}
};

export default translations;
