import React, {useEffect, useState} from 'react';
import {RecordContextProvider, ShowView, SimpleShowLayout, useShowController} from 'react-admin';


const createFileFromBase64 = (base64String, name, mimeType) => {
	try {
		const binaryString = atob(base64String);
		let n = binaryString.length;
		let fileContents = new Uint8Array(n);
		while (n--) {
			fileContents[n] = binaryString.charCodeAt(n);
		}
		return new File([fileContents], name, {type: mimeType});
	} catch (err) {
		return null;
	}
};

const EncryptedStorageShow = (props) => {
	const {record} = useShowController();
	const [file, setFile] = useState('');

	useEffect(() => {
		if (record) {
			const f = createFileFromBase64(record.base64Data, record.id, record.optionalMetadata.mimeType);
			setFile(URL.createObjectURL(f));
		}
	}, [record]);

	return (
		<RecordContextProvider value={record}>
			<ShowView title="resources.salesforce.files.name" {...props}>
				<SimpleShowLayout>
					{record && <iframe title={record.id} src={file} width="100%" height="800" />}
				</SimpleShowLayout>
			</ShowView>
		</RecordContextProvider>
	);
};
export default EncryptedStorageShow;
