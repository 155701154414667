import {Typography} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import {removeTags, useRecordContext} from 'react-admin';


const convertNewLines = (text) => {
	return removeTags(text).replaceAll('\n', '<br>');
};

const PlaintextField = ({source}) => {
	const record = useRecordContext();
	const text = record[source];

	return (
		<Typography><span dangerouslySetInnerHTML={{__html: convertNewLines(text)}} /></Typography>
	);
};

PlaintextField.propTypes = {
	record: PropTypes.object,
	source: PropTypes.string.isRequired
};

export default PlaintextField;
