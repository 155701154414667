import {Button, Card, useTheme} from '@mui/material';
import {styled} from '@mui/material/styles';
import React from 'react';
import {LoginClasses, useNotify, useSafeSetState, useTranslate} from 'react-admin';
import spottedLogo from '../../assets/spotted_logo.svg';
import AuthProvider, {ListenerStates} from '../../providers/AuthProvider';
import {ForgotPasswordForm} from './ForgotPasswordForm';
import {LoginForm} from './LoginForm';
import {MFASetupForm} from './MFASetupForm';
import {NewPasswordForm} from './NewPasswordForm';
import {ResetPasswordForm} from './ResetPasswordForm';
import {TOTPForm} from './TOTPForm';


export const Login = (props) => {
	const [current, setCurrent] = useSafeSetState('login');

	const {classes, className, redirectTo, ...rest} = props;
	const notify = useNotify();
	const translate = useTranslate();
	const theme = useTheme();

	AuthProvider.addListener((type, error) => {

		if (error === undefined) {
			error = null;
		}

		let notification = null;

		switch (type) {
			case ListenerStates.FORGOT_PASSWORD_SUCCESS:
				notification = 'proactive.login.verification_code_sent';
				showResetPasswordDialog();
				break;
			case ListenerStates.NEW_PASSWORD_REQUIRED:
				notification = 'proactive.login.user_registration_needed';
				showNewPasswordRequiredDialog();
				break;
			case ListenerStates.NEW_PASSWORD_SUCCESS:
				notification = 'proactive.login.user_registration_success';
				closeAllDialogs();
				break;
			case ListenerStates.RESET_PASSWORD_REQUIRED:
				showResetPasswordDialog();
				break;
			case ListenerStates.RESET_PASSWORD_SUCCESS:
				notification = 'proactive.login.reset_password_success';
				closeAllDialogs();
				break;
			case ListenerStates.MFA_SETUP_REQUIRED:
				notification = 'proactive.login.setup_mfa_needed';
				showMFASetupRequiredDialog();
				break;
			case ListenerStates.MFA_SETUP_FAILED:
				showMFASetupRequiredDialog();
				break;
			case ListenerStates.MFA_TOTP_CHALLENGE:
				showTOTPChallengeDialog();
				break;
			case ListenerStates.MFA_TOTP_CHALLENGE_SUCCESS:
				notification = 'proactive.login.totp_challenge_success';
				closeAllDialogs();
				break;
			case ListenerStates.MFA_TOTP_CHALLENGE_FAILED:
				error = 'proactive.login.totp_challenge_failed';
				break;
			case ListenerStates.UNKNOWN_ERROR:
				closeAllDialogs();
				break;
			default:
				break;
		}

		if (error) {
			notify(error, {
				type: 'error'
			});
		} else if (notification) {
			notify(notification);
		}
	});

	const showNewPasswordRequiredDialog = () => {
		setCurrent('new-password');
	};

	const showForgotPasswordDialog = () => {
		setCurrent('forgot-password');
	};

	const showResetPasswordDialog = () => {
		setCurrent('reset-password');
	};

	const showMFASetupRequiredDialog = () => {
		setCurrent('setup-mfa');
	};

	const showTOTPChallengeDialog = () => {
		setCurrent('totp');
	};

	const closeAllDialogs = () => {
		setCurrent('login');
		AuthProvider.resetState();
	};

	return (
		<Root {...rest}>
			<Card className={LoginClasses.card} sx={{ marginTop: theme.spacing(4), marginBottom: theme.spacing(2)}}>
				<div className={LoginClasses.avatar}>
					<object type="image/svg+xml" data={spottedLogo} aria-label="Spotted Logo" className={LoginClasses.icon} style={{maxWidth: '50px'}} />
				</div>

				{current === 'login' && <LoginForm redirectTo={redirectTo} />}
				{current === 'totp' && <TOTPForm />}
				{current === 'setup-mfa' && <MFASetupForm />}
				{current === 'forgot-password' && <ForgotPasswordForm onCancel={closeAllDialogs} />}
				{current === 'reset-password' && <ResetPasswordForm />}
				{current === 'new-password' && <NewPasswordForm />}

			</Card>

			{current === 'login' && <Button sx={{
				color: 'white',
				marginBottom: theme.spacing(4),
				textShadow: '1px 1px 1px black'
			}} size="small" onClick={showForgotPasswordDialog}>
				{translate('proactive.login.forgot_password')}
			</Button>}
		</Root>
	);
};

const Root = styled('div', {
	overridesResolver: (props, styles) => styles.root
})(({theme}) => ({
	display: 'flex',
	flexDirection: 'column',
	minHeight: '100vh',
	alignItems: 'center',
	justifyContent: 'flex-start',
	backgroundRepeat: 'no-repeat',
	backgroundSize: 'cover',
	backgroundImage:
		'radial-gradient(circle at 50% 14em, #4B4B4B 0%, #000 60%, #000 100%)',

	[`& .${LoginClasses.card}`]: {
		minWidth: 300,
		marginTop: '6em'
	},
	[`& .${LoginClasses.avatar}`]: {
		margin: '1em',
		display: 'flex',
		justifyContent: 'center'
	},
	[`& .${LoginClasses.icon}`]: {
		backgroundColor: theme.palette.secondary[500]
	}
}));