import {ArcElement, Chart as ChartJS, Colors, Legend, Tooltip} from 'chart.js';
import PropTypes from 'prop-types';
import React, {useEffect, useRef, useState} from 'react';
import {useTranslate} from 'react-admin';
import {Doughnut} from 'react-chartjs-2';


ChartJS.register(ArcElement, Tooltip, Legend, Colors);

const DoughnutChart = ({data, dataKey, valueLabel, showMaxValues, showOtherValuesGrouped}) => {
	const chartRef = useRef(null);
	const translate = useTranslate();
	const [labels, setLabels] = useState([]);
	const [dataSets, setDataSets] = useState([]);
	const [options] = useState({
		responsive: true,
		aspectRatio: 1,
		options: {
			width: '100%'
		},
		plugins: {
			legend: {
				position: 'bottom'
			},
			colors: {
				enabled: true
			},
			tooltip: {
				callbacks: {
					label: (context) => {
						return `${context.parsed}%`;
					}
				}
			}
		}
	});

	useEffect(() => {

		if (!data) {
			return;
		}

		const allValuesSorted = data.sort((a, b) => b.count - a.count);
		const allValuesSum = allValuesSorted.reduce((acc, curr) => acc + curr.count, 0);

		const valuesToShow = allValuesSorted.slice(0, showMaxValues - 1);
		if (showOtherValuesGrouped && allValuesSorted.length > showMaxValues) {

			const remainingValues = allValuesSorted.slice(showMaxValues - 1, allValuesSorted.length - 1);
			const remainingValuesSum = remainingValues.reduce((acc, curr) => acc + curr.count, 0);

			const remainingValuesItem = {};
			remainingValuesItem[dataKey] = translate('resources.salesreport.data.other');
			remainingValuesItem.count = remainingValuesSum;

			valuesToShow.push(remainingValuesItem);
		}

		setLabels(valuesToShow.map(value => {
			if (value[dataKey]) {
				return value[dataKey];
			}
			return translate('resources.salesreport.data.unknown');
		}));

		setDataSets([{
			label: valueLabel,
			data: valuesToShow.map(value => {
				return (value.count * 100 / allValuesSum).toFixed(2);
			})
		}]);

	}, [data, dataKey, valueLabel, showMaxValues, showOtherValuesGrouped, translate]);

	useEffect(() => {
		window.onresize = () => {
			const chart = chartRef.current;
			if (chart) {
				chart.resize();
			}
		};
	}, []);

	return (
		<div>
			<Doughnut ref={chartRef} options={options} data={{labels: labels, datasets: dataSets}} />
		</div>
	);
};


DoughnutChart.propTypes = {
	data: PropTypes.array.isRequired,
	dataKey: PropTypes.string.isRequired,
	valueLabel: PropTypes.string.isRequired,
	showMaxValues: PropTypes.number.isRequired,
	showOtherValuesGrouped: PropTypes.bool
};

export default DoughnutChart;