import {Button, CardContent, CircularProgress, Typography, useTheme} from '@mui/material';
import {styled} from '@mui/material/styles';
import * as React from 'react';
import {Form, LoginFormClasses, minLength, regex, required, TextInput, useNotify, useSafeSetState, useTranslate} from 'react-admin';
import AuthProvider from '../../providers/AuthProvider';


const pwIsSame = function(value, values) {
	if (values.password !== values.passwordConfirmation) {
		return 'proactive.validation.pw_not_equal';
	}
};

const pwValidation = [required(), minLength(12),
	regex(/(?=.*[a-z])/, 'proactive.validation.no_lower_case'),
	regex(/(?=.*[A-Z])/, 'proactive.validation.no_upper_case'),
	regex(/(?=.*[^a-zA-Z0-9])/, 'proactive.validation.no_special_char'),
	regex(/(?=.*[0-9])/, 'proactive.validation.no_number')
];
const pwConfirmationValidation = [required(), pwIsSame];

export const ResetPasswordForm = (props) => {
	const {className} = props;
	const [loading, setLoading] = useSafeSetState(false);
	const translate = useTranslate();
	const notify = useNotify();
	const theme = useTheme();

	const submit = (values) => {
		setLoading(true);
		AuthProvider.resetPassword(values.verificationCode, values.password).then(() => {
			setLoading(false);
		}).catch((err) => {
			setLoading(false);
			notify(err, {
				type: 'error'
			});
		});
	};

	return (
		<StyledForm onSubmit={submit} mode="onChange" noValidate className={className}>
			<CardContent className={LoginFormClasses.content}>

				<Typography variant="h6" color="text.primary" sx={{marginBottom: theme.spacing(2), textAlign: 'center'}}>
					{translate('proactive.login.forgot_password_verification_title')}
				</Typography>

				<Typography variant="body1" color="text.secondary" sx={{marginBottom: theme.spacing(2), textAlign: 'center'}}>
					{translate('proactive.login.forgot_password_verification_description')}
				</Typography>

				<TextInput
					autoFocus
					source="verificationCode"
					label={translate('proactive.login.labels.verification_code')}
					validate={[required()]}
					type="text"
					autoComplete="off"
					fullWidth
				/>

				<TextInput
					source="password"
					label={translate('proactive.login.labels.password')}
					validate={pwValidation}
					type="password"
					autoComplete="new-password"
					fullWidth
				/>

				<TextInput
					source="passwordConfirmation"
					label={translate('proactive.login.labels.password_repeated')}
					validate={pwConfirmationValidation}
					type="password"
					autoComplete="new-password"
					fullWidth
				/>

				<Button
					variant="contained"
					type="submit"
					color="secondary"
					disabled={loading}
					fullWidth
					className={LoginFormClasses.button}
				>
					{loading ? (
						<CircularProgress
							className={LoginFormClasses.icon}
							size={19}
							thickness={3}
						/>
					) : (
						translate('proactive.common.update')
					)}
				</Button>
			</CardContent>
		</StyledForm>
	);
};

const StyledForm = styled(Form, {
	overridesResolver: (props, styles) => styles.root
})(({theme}) => ({
	[`& .${LoginFormClasses.content}`]: {
		width: 300
	},
	[`& .${LoginFormClasses.button}`]: {
		marginTop: theme.spacing(2)
	},
	[`& .${LoginFormClasses.icon}`]: {
		margin: theme.spacing(0.3)
	}
}));