import {Grid} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import {required, SimpleForm, TextInput} from 'react-admin';
import SliderInput from './SliderInput';
import ToggleButtonGroupNumberInput from './ToggleButtonGroupNumberInput';


const minSimilarityLowerThreshold = 0.72;
const minSimilarityUpperThreshold = 0.9;
const minSimilarityDefaultValue = 0.72;

const SalesReportForm = (props) => {

	return (
		<SimpleForm toolbar={props.toolbar}>
			<Grid container rowSpacing={2} columnSpacing={6}>
				<Grid item xs={12}>
					<TextInput name="jobContent" source="jobContent" autoFocus multiline fullWidth
							   label="resources.salesreport.fields.job_content.label" validate={[required()]} />
				</Grid>
				<Grid item xs={12} lg={5}>
					<ToggleButtonGroupNumberInput source="dateInterval" fullWidth
												  label="resources.salesreport.fields.date_interval.label"
												  choices={[
													  {value: 30, label: 'resources.salesreport.fields.date_interval.one_month'},
													  {value: 60, label: 'resources.salesreport.fields.date_interval.two_months'},
													  {value: 90, label: 'resources.salesreport.fields.date_interval.three_months'},
													  {value: 180, label: 'resources.salesreport.fields.date_interval.six_months'}
												  ]} defaultValue={30} />
				</Grid>
				<Grid item xs={12} lg={7}>
					<SliderInput source="minSimilarity" fullWidth
								 label="resources.salesreport.fields.min_similarity.label"
								 defaultValue={minSimilarityDefaultValue} max={minSimilarityUpperThreshold} min={minSimilarityLowerThreshold} step={0.01}
								 marks={[{value: minSimilarityLowerThreshold, label: minSimilarityLowerThreshold}, {value: minSimilarityUpperThreshold, label: minSimilarityUpperThreshold}]} />
				</Grid>
			</Grid>
		</SimpleForm>
	);
};

SalesReportForm.propTypes = {
	toolbar: PropTypes.element.isRequired
};

export default SalesReportForm;
