import {CardContent, CardHeader, Grid, TextField, Typography} from '@mui/material';
import React, {useState} from 'react';
import {EditButton, RecordContextProvider, ShowBase, Title, useShowController, useTranslate} from 'react-admin';
import ApplicantHistogramChart from './ApplicantHistogramChart';
import DoughnutChart from './DoughnutChart';
import ReportCard from './ReportCard';
import SimilarCandidatesList from './SimilarCandidatesList';


const numberFormatter = new Intl.NumberFormat('de-CH');

const SalesReportShow = (props) => {
	const {record} = useShowController();
	const translate = useTranslate();
	const [jobTitleEditable, setJobTitleEditable] = useState(false);

	if (!record) {
		return (<div></div>);
	}

	function handleJobTitleChange(event) {
		record.jobTitle = event.target.value;
		setJobTitleEditable(false);
	}

	return (
		<RecordContextProvider value={record}>
			<ShowBase {...props}>
				<Title title="resources.salesreport.name" />
				<Grid container spacing={4} style={{paddingTop: '1em'}} sx={{color: '#4b4b4b'}}>
					<Grid item xs={12} sx={{marginTop: '1em'}}>
						<div style={{display: 'flex', justifyContent: 'space-between'}}>
							<div className="salesreport-job-title" onDoubleClick={() => {setJobTitleEditable(true)}}>
								{!jobTitleEditable && <Typography variant="h3">{record.jobTitle}</Typography>}
								{jobTitleEditable && <Typography variant="h3"><TextField defaultValue={record.jobTitle} onBlur={handleJobTitleChange} variant="outlined" /></Typography>}
								<Typography variant="h6" sx={{marginTop: '0.5em'}}>Letzte {record.dateInterval} Tage</Typography>
							</div>
							<div>
								<EditButton size="medium" />
							</div>
						</div>
					</Grid>
					<Grid item xs={12}>
						<Grid container spacing={4}>
							<Grid item xs={12} md={6} lg={4}>
								<ReportCard>
									<CardContent>
										<Typography variant="h6">{translate('resources.salesreport.data.similar_candidates')}</Typography>
										<Typography variant="caption" style={{color: '#bbb'}}>&nbsp;</Typography>
										<Typography variant="h2" sx={{marginTop: '0.2em', fontWeight: 400}}>{numberFormatter.format(record.data.similarCandidates.count)}</Typography>
									</CardContent>
								</ReportCard>
							</Grid>
							<Grid item xs={12} md={6} lg={4}>
								<ReportCard>
									<CardContent>
										<Typography variant="h6">{translate('resources.salesreport.data.similar_jobs')}</Typography>
										<Typography variant="caption" style={{color: '#bbb'}}>{translate('resources.salesreport.data.similar_jobs_note')}</Typography>
										<Typography variant="h2" sx={{marginTop: '0.2em', fontWeight: 400}}>{numberFormatter.format(record.data.similarJobs.count)}</Typography>
									</CardContent>
								</ReportCard>
							</Grid>
							<Grid item xs={12} md={12} lg={4}>
								<ReportCard>
									<CardContent>
										<Typography variant="h6">{translate('resources.salesreport.data.avg_applicant_count')}</Typography>
										<Typography variant="caption" style={{color: '#bbb'}}>{translate('resources.salesreport.data.avg_applicant_count_note')}</Typography>
										<Typography variant="h2" sx={{marginTop: '0.2em', fontWeight: 400}}>
											{numberFormatter.format(Math.floor(record.data.similarJobs.avgApplicantCount))}
										</Typography>
									</CardContent>
								</ReportCard>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={12} sx={{marginTop: '1em'}}>
						<Typography variant="h4">{translate('resources.salesreport.data.applicant_behaviour')}</Typography>
					</Grid>
					<Grid item xs={12}>
						<Grid container spacing={4}>
							<Grid item xs={12} md={6} lg={4}>
								<ReportCard>
									<CardHeader title={translate('resources.salesreport.data.locations')} />
									<CardContent sx={{padding: {xs: '0', md: '0 2em', xl: '0 4em'}}}>
										<DoughnutChart dataKey="location" valueLabel={translate('resources.salesreport.data.candidate_count')} showMaxValues={8} data={record.data.locations} />
									</CardContent>
								</ReportCard>
							</Grid>
							<Grid item xs={12} md={6} lg={4}>
								<ReportCard>
									<CardHeader title={translate('resources.salesreport.data.categories')} />
									<CardContent sx={{padding: {xs: '0', md: '0 2em', xl: '0 4em'}}}>
										<DoughnutChart dataKey="cat" valueLabel={translate('resources.salesreport.data.candidate_count')} showMaxValues={8} data={record.data.categories} />
									</CardContent>
								</ReportCard>
							</Grid>
							<Grid item xs={12} md={6} lg={4}>
								<ReportCard>
									<CardHeader title={translate('resources.salesreport.data.segments')} />
									<CardContent sx={{padding: {xs: '0', md: '0 2em', xl: '0 4em'}}}>
										<DoughnutChart dataKey="segment" valueLabel={translate('resources.salesreport.data.candidate_count')} showMaxValues={8} data={record.data.segments} />
									</CardContent>
								</ReportCard>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={12} sx={{marginTop: '1em'}}>
						<Typography variant="h4">{translate('resources.salesreport.data.similar_candidates')}</Typography>
					</Grid>
					<Grid item xs={12}>
						<ReportCard>
							<CardHeader title={translate('resources.salesreport.data.applicant_histogram')} />
							<CardContent>
								<ApplicantHistogramChart data={record.data.similarCandidates.histogram} />
							</CardContent>
						</ReportCard>
					</Grid>
					<Grid item xs={12} sx={{marginBottom: '2em'}}>
						<SimilarCandidatesList data={record.data.similarCandidates.candidates} />
					</Grid>
				</Grid>
			</ShowBase>
		</RecordContextProvider>
	);
};
export default SalesReportShow;
