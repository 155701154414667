import {CREATE, GET_ONE, UPDATE} from 'react-admin';
import Logger from '../logger';
import {getResourcePath} from './DataProvider';
import {defaultClient, emptyResponse} from './DefaultClient';

class defaultClientWithCaching extends defaultClient {

	async handle(type, resource, params) {
		Logger.debug(`DataProvider.handle: type=${type} resource=${resource} params=${JSON.stringify(params)}`);

		switch (type) {
			case CREATE:
			case UPDATE:

				const path = getResourcePath(resource, params);

				const request = await this._buildRequest(type, resource, path, params);
				const json = await this._sendRequest(request);

				const response = this._convertResponse(type, json, resource, params);

				this._cacheClear();
				this._cacheSet(response.data.id, response);

				return response;

			case GET_ONE:

				const cached = this._cacheGet(params.id);
				if (cached !== null) {
					return cached;
				}

				throw new Error();

			default:
				return emptyResponse;
		}
	}

	_cacheGet(key) {
		return JSON.parse(sessionStorage.getItem(key))
	}

	_cacheSet(key, object) {
		sessionStorage.setItem(key, JSON.stringify(object))
	}

	_cacheClear() {
		sessionStorage.clear()
	}
}

const singleton = new defaultClientWithCaching();
const handler = (type, resource, params) => {
	return singleton.handle(type, resource, params);
};
export default handler;