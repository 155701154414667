import {UploadFile} from '@mui/icons-material';
import React from 'react';
import {Create, FileField, FileInput, required, SaveButton, SimpleForm, Toolbar, useTranslate} from 'react-admin';
import {useParams} from 'react-router';
import {useSearchParams} from 'react-router-dom';


const maxFileSize = 30 * 1024 * 1024;

const isValidURL = (url) => {
	return url.startsWith('https');
};

const convertFileToBase64 = (file) =>
	new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.onload = () => {
			return resolve(btoa(reader.result));
		};
		reader.onerror = reject;

		reader.readAsBinaryString(file);
	});

const SalesforceFilesUpload = (props) => {

	const routeParams = useParams();
	const [urlQueryParams] = useSearchParams();

	const salesforceRecordId = routeParams.id;
	const salesforceObjectType = urlQueryParams.get('objectType');

	const translate = useTranslate();
	const UploadToolbar = props => (
		<Toolbar {...props}>
			<SaveButton label="resources.salesforce.files.action.save" icon={<UploadFile />} />
		</Toolbar>
	);

	const encodeFileContents = async (data) => {
		data.salesforceRecordId = salesforceRecordId;
		if (data.file !== undefined && data.file.rawFile !== undefined) {
			data.base64Data = await convertFileToBase64(data.file.rawFile);
			data.optionalMetadata = {
				mimeType: data.file.rawFile.type,
				salesforceObjectType: salesforceObjectType
			};
			data.file = undefined;
		}
		return data;
	};

	const redirect = (resource, id, data) => {
		if (data && isValidURL(data.url)) {
			window.location.replace(data.url);
		}
		return `${resource}/${id}/show`;
	};

	const onDropRejected = (rejections, event) => {
		if (rejections.length > 0) {
			if (rejections[0].errors.length > 0) {
				if (rejections[0].errors[0].code === 'file-too-large') {
					alert(translate('proactive.errors.error_5000'));
					return;
				}
			}
		}
		alert(translate('proactive.errors.file_rejection_unknown'));
	}

	if (salesforceObjectType == null || salesforceObjectType === '') {
		// TODO: show error message
		return;
	}

	return (
		<Create title="resources.salesforce.files.name" transform={encodeFileContents} redirect={redirect} {...props}>
			<SimpleForm toolbar={<UploadToolbar />}>
				<FileInput name="file" source="file" maxSize={maxFileSize} options={{onDropRejected: onDropRejected}} label="resources.salesforce.files.fields.base64Data.label" validate={[required()]}>
					<FileField source="src" title="title" />
				</FileInput>
				<small className="input-help">{translate('resources.salesforce.files.fields.base64Data.help', {mb: maxFileSize / 1024 / 1024})}</small>
			</SimpleForm>
		</Create>
	);
};
export default SalesforceFilesUpload;
