
class GlobalsEnvLoader {

	constructor(prefix) {
		this.prefix = prefix;
		this.vars = {};
		this.loadFromEnv();
	}

	loadFromEnv() {
		let env = process.env;
		for (let key in env) {
			if (env.hasOwnProperty(key)) {
				if (key.startsWith(this.prefix)) {
					this.vars[key.substr(this.prefix.length)] = env[key];
				}
			}
		}
	}
}

const Globals = new GlobalsEnvLoader("REACT_APP_").vars;
export default Globals;
