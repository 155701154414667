import {CREATE, GET_ONE} from 'react-admin';
import Logger from '../logger';
import {defaultClient, emptyResponse} from './DefaultClient';


class salesforceClient extends defaultClient {

	async handle(type, resource, params) {
		Logger.debug(`DataProvider.handle: type=${type} resource=${resource} params=${JSON.stringify(params)}`);

		switch (type) {
			case CREATE:
				return super.handle(type, resource, params);
			case GET_ONE:
				return {data: {id: params.id}};
			default:
				return emptyResponse;
		}
	}

	_convertResponse(type, json, resource, params) {
		const response = {};
		response.data = json;
		response.data.id = params.data.salesforceRecordId;
		return response;
	}
}

const singleton = new salesforceClient();
const handler = (type, resource, params) => {
	return singleton.handle(type, resource, params);
};
export default handler;