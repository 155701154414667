import {EnhancedEncryption, QueryStats} from '@mui/icons-material';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import raTranslationsEn from 'ra-language-english';
import raTranslationsDe from 'ra-language-german';
import React, {Component} from 'react';
import {Admin, defaultTheme, Resource} from 'react-admin';
import paTranslations from '../../locales/index';
import Logger from '../../logger';
import AuthProvider, {hasRole, Roles} from '../../providers/AuthProvider';
import dataProvider from '../../providers/DataProvider';
import DecryptShow from '../Decrypt/DecryptShow';
import EncryptCreate from '../Encrypt/EncryptCreate';
import EncryptShow from '../Encrypt/EncryptShow';
import EncryptedStorageShow from '../EncryptedStorage/EncryptedStorageShow';
import {Login} from '../Login/Login';
import MediaFileShow from '../MediaFile/MediaFileShow';
import SalesforceFilesUpload from '../Salesforce/SalesforceFilesUpload';
import SalesReportCreate from '../SalesReport/SalesReportCreate';
import SalesReportEdit from '../SalesReport/SalesReportEdit';
import SalesReportShow from '../SalesReport/SalesReportShow';
import './App.css';


const theme = {
	...defaultTheme,
	palette: {
		...defaultTheme.palette,
		primary: {
			light: '#4D4B4B',
			main: '#4D4B4B',
			dark: '#4D4B4B',
			contrastText: '#66FFA3'
		},
		secondary: {
			...defaultTheme.palette.secondary,
			light: '#4D4B4B',
			main: '#4D4B4B',
			dark: '#4D4B4B',
			contrastText: '#FFFFFF'
		}
	},
	typography: {
		fontFamily: ['Open Sans'].join(',')
	},
	shape: {
		borderRadius: 16
	}
};

const messages = {
	de: {...raTranslationsDe, ...paTranslations.de},
	en: {...raTranslationsEn}
};
export const i18nProvider = polyglotI18nProvider(locale => messages[locale], 'de', {allowMissing: true});

const getResources = (permissions) => {

	let resources = [];

	if (hasRole(permissions, Roles.TA)) {
		resources.push([
			<Resource name="encrypt" list={EncryptCreate} show={EncryptShow} icon={EnhancedEncryption} />,
			<Resource name="decrypt" show={DecryptShow} />,
			<Resource name="mediafiles" show={MediaFileShow} />
		]);
	}

	if (hasRole(permissions, Roles.TA) || hasRole(permissions, Roles.Sales)) {
		resources.push([
			<Resource name="salesreport" list={SalesReportCreate} show={SalesReportShow} edit={SalesReportEdit} icon={QueryStats} />
		]);
	}

	if (hasRole(permissions, Roles.StorageCandidates) || hasRole(permissions, Roles.StorageProjects)) {
		resources.push([
			<Resource name="salesforce/files" edit={SalesforceFilesUpload} />,
			<Resource name="storage" show={EncryptedStorageShow} />
		]);
	}

	return resources;
};

class App extends Component {

	setupLogger = () => {
		if (process.env.NODE_ENV !== 'production') {
			Logger.useDefaults();

			if (process.env.NODE_ENV === 'development') {
				Logger.setLevel(Logger.DEBUG);
			} else {
				Logger.setLevel(Logger.INFO);
			}
		}
	};

	getLoginComponent = () => {
		const path = window.location.hash.slice(1);
		if (path.length > 0 && !path.startsWith('/login')) {
			return () => (<Login redirectTo={path} />);
		}
		return Login;
	};

	render() {
		this.setupLogger();
		const loginComponent = this.getLoginComponent();
		return (
			<Admin title="Spotted" dataProvider={dataProvider} authProvider={AuthProvider.handler} requireAuth locale="de"
				   i18nProvider={i18nProvider}
				   loginPage={loginComponent} theme={theme} disableTelemetry={true}>
				{permissions => getResources(permissions)}
			</Admin>
		);
	}
}

export default /*withTheme()*/(App);
