import PropTypes from 'prop-types';
import React from 'react';
import {useRecordContext} from 'react-admin';


const isValidURL = (url) => {
	return url.startsWith('http');
};

const RedirectField = ({source}) => {
	const record = useRecordContext();
	const url = record[source];

	if (isValidURL(url)) {
		// redirect without adding to browser history (by using location.replace)
		window.location.replace(record[source]);
		return <div>Redirecting to <a href={url} rel="noopener noreferrer" style={{'overflowWrap': 'anywhere'}}>{url}</a> ...</div>;
	} else {
		return <div>Invalid URL: {url}</div>;
	}
};

RedirectField.propTypes = {
	record: PropTypes.object,
	source: PropTypes.string.isRequired
};

export default RedirectField;
