import {AuthenticationDetails, CognitoUser, CognitoUserPool} from 'amazon-cognito-identity-js';
import {AUTH_CHECK, AUTH_ERROR, AUTH_GET_PERMISSIONS, AUTH_LOGIN, AUTH_LOGOUT} from 'react-admin';
import Globals from '../globals';
import Logger from '../logger';


class authProvider {

	constructor(userPoolId, clientId) {
		this.userPool = new CognitoUserPool({UserPoolId: userPoolId, ClientId: clientId});
		this.user = null;
		this.listeners = [];
		this.mfaSecretCode = null;

		this.loginReject = null;
		this.loginResolve = null;
	}

	handler = (type, params) => {
		Logger.debug(`authProvider.handler: type=${type}`);

		if (type === AUTH_LOGIN) {
			return this._handleLogin(params.username, params.password);
		}

		if (type === AUTH_LOGOUT) {
			return this._handleLogout();
		}

		if (type === AUTH_ERROR) {
			return this._handleAuthError(params);
		}

		if (type === AUTH_CHECK) {
			return this._handleAuthCheck(params);
		}

		if (type === AUTH_GET_PERMISSIONS) {
			return this._handleGetPermissions(params);
		}

		return Promise.reject(`Unknown auth type: ${type}`);
	};

	addListener(listener) {
		if (listener) {
			this.listeners.push(listener);
		}
	}

	removeListener(listener) {
		if (listener) {
			this.listeners = this.listeners.filter(l => l !== listener);
		}
	}

	setListenerState(state, error) {
		this.listeners.forEach(l => l(state, error));
	}

	setNewPassword = (password, firstname, lastname) => {

		const instance = this;

		return new Promise((resolve, reject) => {
			if (instance.user) {

				let userAttributes = {
					name: firstname,
					family_name: lastname
				};

				instance.user.completeNewPasswordChallenge(password, userAttributes, {
					onSuccess: () => {
						instance.setListenerState(ListenerStates.NEW_PASSWORD_SUCCESS);
						resolve();
						instance._resolveLoginPromise();
					},
					onFailure: (error) => {
						Logger.debug(error);
						reject('proactive.errors.' + error.code);
					},
					mfaSetup: () => {
						instance.setupMFA();
					}
				});

			} else {
				reject('proactive.errors.no_user_stored');
			}
		});
	};

	forgotPassword = (username) => {
		const instance = this;

		let user = new CognitoUser({
			Username: username,
			Pool: instance.userPool
		});

		return new Promise((resolve, reject) => {
			user.forgotPassword({
				onSuccess: () => {
					instance.user = user;
					resolve();
					instance.setListenerState(ListenerStates.FORGOT_PASSWORD_SUCCESS);
				},
				onFailure: (error) => {
					Logger.debug(error);
					instance.user = user;
					reject('proactive.errors.' + error.code);
				}
			});
		});
	};

	resetPassword = (code, newPassword) => {
		const instance = this;

		return new Promise((resolve, reject) => {
			if (instance.user) {
				instance.user.confirmPassword(code, newPassword, {
					onSuccess: () => {
						resolve();
						instance.setListenerState(ListenerStates.RESET_PASSWORD_SUCCESS);
					},
					onFailure: (error) => {
						Logger.debug(error);
						reject('proactive.errors.' + error.code);
					}
				});
			} else {
				reject('proactive.errors.no_user_stored');
			}
		});
	};

	setupMFA = () => {
		const instance = this;

		instance.user.associateSoftwareToken({
			associateSecretCode: function(secretCode) {
				Logger.debug('got secret code ' + secretCode);
				instance.mfaSecretCode = secretCode;
				instance.setListenerState(ListenerStates.MFA_SETUP_REQUIRED);
			},
			onFailure: function(error) {
				Logger.error('associateSoftwareToken error ' + JSON.stringify(error));
				instance.setListenerState(ListenerStates.MFA_SETUP_FAILED, 'proactive.errors.' + error.code);
			}
		});
	};

	getMFASecretCode = () => {
		return this.mfaSecretCode;
	};

	setupMFAVerifyCode = (mfaCode) => {
		const instance = this;
		return new Promise((resolve, reject) => {
			instance.user.verifySoftwareToken(mfaCode, 'Authenticator', {
				onSuccess: function(session) {
					resolve();
					instance._resolveLoginPromise();
				},
				onFailure: function(error) {
					Logger.error('fail ' + JSON.stringify(error));
					reject('proactive.errors.' + error.code);
				}
			});
		});
	};

	sendMFACode = (mfaCode) => {
		const instance = this;
		return new Promise((resolve, reject) => {
			instance.user.sendMFACode(mfaCode, {
				onSuccess: function(session) {
					resolve();
					instance._resolveLoginPromise();
				},
				onFailure: function(error) {
					Logger.error('sendMFACode error : ' + JSON.stringify(error));
					reject('proactive.errors.' + error.code);
				}
			}, 'SOFTWARE_TOKEN_MFA');
		});
	};

	resetState = () => {

		this._rejectLoginPromise();

		this.loginResolve = null;
		this.loginReject = null;
		this.mfaSecretCode = null;
	};

	getUsername = () => {
		if (this.user) {
			return this.user.getUsername();
		}
		return '';
	};

	getAccessToken = () => {
		return this._getSession().then(session => {
			return session.accessToken;
		});
	};

	_getSession = () => {
		const instance = this;

		return new Promise((resolve, reject) => {

			let user = instance.user;

			if (!user) {
				user = instance.userPool.getCurrentUser();
				if (!user) {
					return reject('proactive.errors.no_user_stored');
				}
				instance.user = user;
			}

			if (user.getSignInUserSession() && user.getSignInUserSession().isValid()) {
				return resolve(user.getSignInUserSession());
			} else {
				user.getSession(function(error, session) {
					if (error) {
						return reject('proactive.errors.get_session_failed');
					} else {
						return resolve(session);
					}
				});
			}
		});
	};

	_getGroupsFromToken = (token) => {

		return new Promise((resolve, reject) => {

			const cognitoGroups = token.payload['cognito:groups'];

			if (!token || !token.payload || !cognitoGroups || !Array.isArray(cognitoGroups) || cognitoGroups.length < 1) {

				Logger.error(`Failed to get permissions from user token`);
				reject(`proactive.errors.wrong_user_configuration`);

			} else {

				const permissions = [];
				for (let g of cognitoGroups) {
					permissions.push(g.toUpperCase());
				}
				resolve(permissions);
			}
		});
	};

	_handleLogin = (username, password) => {
		const instance = this;

		let authenticationDetails = new AuthenticationDetails({
			Username: username,
			Password: password
		});

		let user = new CognitoUser({
			Username: username,
			Pool: instance.userPool
		});

		return new Promise(function(resolve, reject) {
			user.authenticateUser(authenticationDetails, {
				onSuccess: () => {
					instance.user = user;
					instance._handleAuthCheck().then(() => {
						resolve();
					}).catch(e => {
						instance.user.signOut();
						reject(e);
					});
				},
				onFailure: (error) => {

					switch (error.code) {
						case 'PasswordResetRequiredException':
							instance.user = user;
							instance.setListenerState(ListenerStates.RESET_PASSWORD_REQUIRED);

							// keep promise objects to complete later
							instance.loginReject = reject;
							instance.loginResolve = resolve;

							break;
						default:
							reject('proactive.errors.' + error.code);
					}
				},
				newPasswordRequired: () => {
					instance.user = user;
					instance.setListenerState(ListenerStates.NEW_PASSWORD_REQUIRED);

					// keep promise objects to complete later
					instance.loginReject = reject;
					instance.loginResolve = resolve;
				},
				mfaSetup: (challengeName, challengeParameters) => {
					instance.user = user;
					instance.setupMFA();

					// keep promise objects to complete later
					instance.loginReject = reject;
					instance.loginResolve = resolve;
				},
				totpRequired: (challengeName, challengeParameters) => {
					instance.user = user;
					instance.setListenerState(ListenerStates.MFA_TOTP_CHALLENGE);

					// keep promise objects to complete later
					instance.loginReject = reject;
					instance.loginResolve = resolve;
				}
			});
		});
	};

	_resolveLoginPromise = () => {
		if (typeof this.loginResolve == 'function') {
			this.loginResolve();
		}
	};

	_rejectLoginPromise = () => {
		if (typeof this.loginReject == 'function') {
			this.loginReject();
		}
	};

	_handleLogout = () => {

		if (this.user) {
			this.user.signOut();
			this.user = null;
		}

		return Promise.resolve();
	};

	_handleAuthError = (params) => {

		switch (params.httpStatus) {
			case 401:
				return Promise.reject(params.message);
			case 403:
				return Promise.resolve(params.message);
			default:
				return Promise.resolve();
		}
	};

	_handleAuthCheck = () => {
		const instance = this;

		return instance.getAccessToken().then((token) => {

			return instance._getGroupsFromToken(token).then(groups => {

				if (hasAnyKnownRole(groups)) {
					return Promise.resolve();
				}

				return Promise.reject('proactive.errors.wrong_user_configuration');

			});
		});
	};

	_handleGetPermissions = () => {
		const instance = this;

		return instance.getAccessToken().then(token => {
			return instance._getGroupsFromToken(token);
		});
	};
}

export const Roles = {
	TA: 'ta',
	Sales: 'sales',
	StorageCandidates: 'storage-candidates',
	StorageProjects: 'storage-projects'
};

export function hasAnyKnownRole(permissions) {
	const knownRoles = Object.entries(Roles).map((role) => {
		return role[1].toUpperCase();
	});
	return permissions && permissions.some(r => knownRoles.includes(r.toUpperCase()));
}

export function hasRole(permissions, role) {
	return permissions && permissions.indexOf(role.toUpperCase()) !== -1;
}

export const ListenerStates = {
	FORGOT_PASSWORD_SUCCESS: 1,
	FORGOT_PASSWORD_FAILED: 2,
	NEW_PASSWORD_REQUIRED: 3,
	NEW_PASSWORD_SUCCESS: 4,
	NEW_PASSWORD_FAILED: 5,
	RESET_PASSWORD_REQUIRED: 6,
	RESET_PASSWORD_SUCCESS: 7,
	RESET_PASSWORD_FAILED: 8,
	MFA_SETUP_REQUIRED: 9,
	MFA_SETUP_SUCCESS: 10,
	MFA_SETUP_FAILED: 11,
	MFA_TOTP_CHALLENGE: 12,
	MFA_TOTP_CHALLENGE_SUCCESS: 13,
	MFA_TOTP_CHALLENGE_FAILED: 14,
	UNKNOWN_ERROR: 15
};

const AuthProvider = new authProvider(Globals.COGNITO_USER_POOL_ID, Globals.COGNITO_CLIENT_ID);
export default AuthProvider;
