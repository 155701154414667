import {PendingActions} from '@mui/icons-material';
import React from 'react';
import {Create, SaveButton, Toolbar} from 'react-admin';
import SalesReportForm from './SalesReportForm';


const SalesReportCreate = (props) => {

	const CreateToolbar = props => (
		<Toolbar {...props}>
			<SaveButton label="resources.salesreport.action.create" icon={<PendingActions />} />
		</Toolbar>
	);

	return (
		<Create title="resources.salesreport.name" redirect="show" sx={{marginBottom: '2em'}} {...props}>
			<SalesReportForm toolbar={<CreateToolbar/>} />
		</Create>
	);
};
export default SalesReportCreate;
